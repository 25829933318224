import Separator from "@/components/atoms/Separator";
import IconOpenAccount from "../../../assets/icon/IconOpenAccount.svg";
import IconGraphIncrease from "../../../assets/icon/IconGraphIncrease.svg";
import IconDollarCoin from "../../../assets/icon/IconDollarCoin.svg";
import BgInvestLp from "../../../assets/img/BgInvestLp.png";
import Styles from "./styles.module.scss";
import Image from "next/image";
import Section from "../Section";

const InvestLP = ({t}:any) => {
	return (
		<div id={Styles.containerInvest}>
			<section className={Styles.cardContainer}>
				<Image src={IconOpenAccount} className={Styles.investImage} alt="Icon de abrir conta" width={200} height={200} />
				<div>
					<header>
						<p className="coinLivreText">1{t.LP.step}</p>
						<h4>{t.LP.OpenAccount}</h4>
					</header>
					<div>
						<p>{t.LP.OADescription}</p>
					</div>
				</div>
			</section>

			<section className={Styles.cardContainer}>
				<Image src={IconDollarCoin} className={Styles.investImage} alt="Icone de porquinho com dolar" width={200} height={200} />
				<div>
					<header>
						<p className="coinLivreText">2{t.LP.step}</p>
						<h4>{t.LP.DoDeposit}</h4>
					</header>
					<div>
						<p>{t.LP.DDDescription}</p>
					</div>
				</div>
			</section>

			<section className={Styles.cardContainer}>
				<Image src={IconGraphIncrease} className={Styles.investImage} alt="Icon Grafico de crescimento" width={200} height={200} />
				<div>
					<header>
						<p className="coinLivreText">3{t.LP.step}</p>
						<h4>{t.LP.InvestProject}</h4>
					</header>
					<div>
						<p>{t.LP.IPDescription}</p>
					</div>
				</div>
			</section>
		</div>
	);
};

export default InvestLP;
