import ProjectCardLP from "@/components/molecules/ProjectCardLP/ProjectCardLP";
import Section from "../Section";
import Styles from "./styles.module.scss";
import CLCC from "../../../public/images/CLCC.webp";
import Recebiveis from "@/public/images/ReceivableImage.jpg";
import CLCAR from "@/public/images/CLCAR.webp";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Projects } from "@/types/Projects";
import ArrowLeftGradient from '@/assets/icon/ArrowLeftGradient.svg'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Image from "next/image";
import { useRouter } from "next/router";

export default function ProjectLP({ t }: any) {

	const [activeCardCarousel, setActiveCardCarousel] = useState(0);
	const [carouselProjects, setCarouselProjects] = useState<{ id: string, name: string, acronym: string, summary: string }[] | null>(null);
	const carouselRef = useRef<HTMLDivElement | null>(null);
	const router = useRouter();

	const getCarouselItems = () => {
		if (carouselProjects) {
			switch (activeCardCarousel) {
				case 1:
					return carouselProjects.filter(({ acronym }) => acronym.includes('CLCC'))
				case 2:
					return carouselProjects.filter(({ acronym }) => acronym.includes('CLREC'))
				case 3:
					return carouselProjects.filter(({ acronym }) => !acronym.includes('CLCC') && !acronym.includes('CLREC') && !acronym.includes('CNLT'))
				default: return [];
			}
		}
	}
	useEffect(() => {
		const getAllProjects = async () => {
			const response = await axios.get(String(process.env.NEXT_PUBLIC_PROJETO_ID_URL))
			const projects: Projects[] = response.data.map(({ acronym, summary, name, id }: { id: string, name: string, acronym: string, summary: string }) => {
				return {
					acronym,
					summary,
					name,
					id
				}
			})
			setCarouselProjects(projects)

		}

		getAllProjects();
	}, [])



	useEffect(() => {
		const carouselRunning = setInterval(() => {
			if (carousel && carousel.length > 3) {
				if (carouselRef.current!.scrollLeft + carouselRef.current!.offsetWidth >= carouselRef.current!.scrollWidth) {
					carouselRef.current!.scrollTo({
						left: 0,
						behavior: 'smooth'
					});
				} else {
					carouselRef.current!.scrollTo({
						left: carouselRef.current!.scrollLeft + 370,
						behavior: 'smooth'
					});
				}
			}
		}, 10000);

		return () => clearInterval(carouselRunning);

	}, [activeCardCarousel])

	let carousel = getCarouselItems();
	const cardHeaders = [t.LP.CarbonCard, t.LP.ReceivableCard, t.LP.OtherProjectsCard]

	return (
		<div className={Styles.sectionMainProject}>
			<Section
				className={Styles.section}
				id="introducao"
				label="introducao-title"
				desc="introducao-description"
				justify="between"
				hidden={false}
			>
				{activeCardCarousel > 0 &&
					<button className={`coinLivreText ${Styles.buttonToBack}`}
						onClick={() => setActiveCardCarousel(0)}>
						<Image src={ArrowLeftGradient} alt="seta voltar" width={30} height={40} />
						Voltar para as demais categorias
					</button>}
				<header className={Styles.headerMainProject}>
					{!(activeCardCarousel > 0) ? <h3>{t.LP.coinlivreLaunchs}</h3> : <h3 className={Styles.projectsTitle}>{cardHeaders[activeCardCarousel - 1]}</h3>}
					<p>{activeCardCarousel === 0 ? t.LP.coinlivreViability : t.LP.cardHeaderText}</p>
				</header>
			</Section>

			<div className={Styles.containerCards}>
				<Section
					className={Styles.sectionCards}
					id="introducao"
					label="introducao-title"
					desc="introducao-description"
					justify="between"
					hidden={false}
				>
					<div className={activeCardCarousel > 0 ? Styles.containerProjectsRelativesCards : Styles.containerRelativesCards}
						ref={carouselRef}>
						{activeCardCarousel === 0 ?
							<>
								<ProjectCardLP
									title={t.LP.CarbonCard}
									description={t.LP.CCDesc}
									bgImage={CLCC}
									link={"#"}
									t={t}
									setActiveCardCarousel={setActiveCardCarousel}
									carousel={1}
									isFutureProject="biodiversidade"
								/>
								<ProjectCardLP
									title={t.LP.ReceivableCard}
									description={t.LP.RCDesc}
									bgImage={Recebiveis}
									link={"#"}
									t={t}
									setActiveCardCarousel={setActiveCardCarousel}
									carousel={2}
									isFutureProject
								/>
								<ProjectCardLP
									title={t.LP.OtherProjectsCard}
									description={t.LP.OPCDesc}
									bgImage={CLCAR}
									link={"#"}
									t={t}
									setActiveCardCarousel={setActiveCardCarousel}
									carousel={3}
									isFutureProject
								/>
							</>
							:
							<>
								{carousel?.map(({ name, acronym, id }) => (
									<ProjectCardLP key={acronym} id={id} router={router}
										title={name} bgImage={`/images/${acronym}.webp`} description={t.Token[`${acronym}Summary`]} link={'#'} t={t}
										setActiveCardCarousel={setActiveCardCarousel} activeCardCarousel={activeCardCarousel} carousel={0} acronym={acronym} />
								))}
							</>
						}
					</div>
					{carousel && carousel.length > 3 &&
						<div className={Styles.carouselButtons}>
							<button onClick={() => carouselRef.current!.scrollTo({
								left: carouselRef.current!.scrollLeft - 370,
								behavior: 'smooth'
							})}><KeyboardArrowLeftIcon /></button>
							<button onClick={() => carouselRef.current!.scrollTo({
								left: carouselRef.current!.scrollLeft + 370,
								behavior: 'smooth'
							})}><KeyboardArrowRightIcon /></button>
						</div>}
				</Section>
			</div>
		</div>
	);
};
