import React from 'react'
import Styles from './styles.module.scss'

type Props = {
  color: string,
  size?: number,
  classname?: any
}

const Separator = ({ color, size, classname }: Props) => {
  return (
    <div
      style={{ backgroundColor: color, width: `${size}px` }}
      className={`${Styles.separator} ${classname}`}
      aria-hidden={true}
    />
  )
}

export default Separator