import React from 'react'
import Styles from './styles.module.scss'

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next'

type Props = {
  id: string,
  label: string,
  hidden: boolean,
  text: string,
  onClick: any,
  disabled?: boolean,
  className?: string,
  size?: number;
  type?: any;
  width?: string;
  margin?: string;
  zIndex?: string;
  dataTest?: string
}

const Button = ({ id, label, hidden, disabled, text, onClick, className, size, type, width, margin, zIndex, dataTest }: Props) => {
  const { t } = useTranslation('login');

  return (
    <button
      id={id}
      aria-label={label}
      aria-hidden={hidden}
      disabled={disabled}
      data-test-id={dataTest}
      onClick={onClick}
      className={`${Styles.button} ${className} `}
      style={{ fontSize: size, width, margin: margin, zIndex: zIndex, }}
      type={type}
    >
      {t(text)}
    </button>
  )
}

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['login']))
    }
  }
}

export default Button