import NavContentLP from "@/components/atoms/NavContentLP";
import Styles from "./styles.module.scss";
import {useEffect, useState } from "react";

interface NavContentProps {
  id: number;
  text: string;
  content: string;
  styledWord?: string
}



export default function NavigationLP({t}:any) {
  const [selectedButton, setSelectedButton] = useState(1);
  
  const ButtonTextOptions =  {
      WHAT_DO: t.LP.WhatDo,
      WHAT_IS:  t.LP.WhatIs,
      HOW_DO: t.LP.HowDo,
      WHY_DO: t.LP.WhyDo,
      WHO_ARE: t.LP.WhoAre,
    }

  const ButtonText: Record<string, NavContentProps> = {
    [ButtonTextOptions.WHAT_DO]: {
      id: 1,
      text: ButtonTextOptions.WHAT_DO,
      content: t.LP.WhatDoDescription,
    },
    [ButtonTextOptions.WHAT_IS]: {
      id: 2,
      text: ButtonTextOptions.WHAT_IS,
      content: t.LP.WhatIsDescription,
    },
    [ButtonTextOptions.HOW_DO]: {
      id: 3,
      text: ButtonTextOptions.HOW_DO,
      content: t.LP.HowDoDescription,
    },
    [ButtonTextOptions.WHY_DO]: {
      id: 4,
      text: ButtonTextOptions.WHY_DO,
      content: t.LP.WhyDoDescription,
    },
    [ButtonTextOptions.WHO_ARE]: {
      id: 5,
      text: ButtonTextOptions.WHO_ARE,
      content: t.LP.WhoAreDescription,
    },
  };

  return (
    <div className={`${Styles.navigationContainer} ${selectedButton === 5 ? Styles.navigationContainerExpanded : null}`}>
      {Object.keys(ButtonText).map((key) => {
        const ButtonOption = key;
        const { id, text, content } = ButtonText[ButtonOption];
        return (
          <>
            <NavContentLP textButton={text} id={id} content={content} setSelectedButton={setSelectedButton} selectedButton={selectedButton} t={t}/>
            
          </>
        );
      })}
    </div>
  );
}
