import { ButtonProps } from "@mui/material";
import Styles from "./styles.module.scss";
import { Dispatch, SetStateAction, useEffect } from "react";
import styled from "@emotion/styled";

interface NavContentProps {
  id: number;
  textButton: string;
  content: string;
  setSelectedButton: Dispatch<SetStateAction<number>>;
  selectedButton: number;
  t:any
}

export default function NavContentLP({
  textButton,
  id,
  content,
  setSelectedButton,
  selectedButton,
  t
}: NavContentProps) {
  const getStyledWord = () => {
    if (textButton.includes("CoinLivre") || textButton.includes("CNLT")) {
      return (
        <>
          {textButton.split(" ").map((word, index) =>
            word === "CoinLivre" ? (
              <span key={"clbtn" + index} id={Styles.cl}>CoinLivre </span>
            ) : word === "CNLT" ? (
              <span key={"cnltbtn" + index}>CNLT</span>
            ) : `${word} `
          )}
        </>
      );
    } else {
      return textButton;
    }
  };

  return (
    <div className={Styles.contentContainer}>
      <button
        className={`${Styles.button} ${selectedButton === id
          ? Styles["button-active"]
          : Styles["button-unactive"]
          }`}
        onClick={() => setSelectedButton(id)}
      >
        {getStyledWord()}
      </button>
      <p
        className={`${Styles.cardContent} ${selectedButton === id ? Styles.cardContentVisible : Styles.cardContentHidden
          }`}
      >
        {content}
      </p>
    </div>
  );
}
