import NavigationLP from "@/components/molecules/NavigationLP";
import Section from "../Section";
import Styles from "./styles.module.scss";

export default function AboutLP({t}:any) {
  return (
    <Section
      id="introducao"
      label="introducao-title"
      desc="introducao-description"
      hidden={false}
      className={Styles["section"]}
      justify={""}
    >
      <div className={Styles.container}>
        <h1 className={Styles.h1}>{t.LP.anyQuestion}</h1>
        <NavigationLP t={t} />
        
      </div>
    </Section>
  );
}

/* Image: 32540979_abstract_background_with_flowing_lines_design_2910;
background: url("32540979_abstract_background_with_flowing_lines_design_2910.png");
background-size: cover;
opacity: 0.50;
*/
